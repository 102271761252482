<!--
 * @Author: cuihaopeng cuihaopeng@slodon.cn
 * @Date: 2024-09-18 15:13:57
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2025-01-08 09:55:36
 * @FilePath: /bzb_duoyuyan_pc/src/components/MemberTitle.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
// 个人中心路由切换顶部导航面包屑 @ljp-2021/2/13
<template>
    <div class="member_title_breadcrumb">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            style="line-height: 40px;font-size: 13px;"
            >
            <el-breadcrumb-item :to="{path:'/index'}" replace>{{L['首页']}}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/member/index'}" replace>{{L['个人中心']}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="!memberPath">{{memberTitle}}</el-breadcrumb-item>
            <el-breadcrumb-item v-else :to="{path:memberPath}" replace>{{memberTitle}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{memberTitleS}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
import {getCurrentInstance} from "vue"
import {elBreadcrumb,elBreadcrumbItem} from "element-plus";
export default {
    name:'MemberTitle',
    components:{
        elBreadcrumb,
        elBreadcrumbItem,
    },
    props:["memberTitle","memberPath","memberTitleS"],
    setup(){
        const {proxy} = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        return {
            L
        }
    }
}
</script>
<style lang="scss">
.member_title_breadcrumb{
    padding-left: 10px;
    background: $colorK;
    .el-breadcrumb__item{
        height: 46px;
        line-height: 46px;
    }
    .el-breadcrumb__inner {
        color:#666666!important;
        font-weight: 700!important;
    }
    .is-link{
        color: #666666!important;
        font-weight: 400!important;
        &:hover{
            color: #333333!important;
            font-weight: 700!important;
        }
    }
}

</style>
